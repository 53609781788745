.contactusTitle h1{
    /* text-align: center;
    margin-top: 3rem;
    color:#333333;
    font-size: 96px;
    font-weight: bold; */
}

.contactUsContainer{
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    gap: 3rem;
}
.contactDesc{
    flex:6
}
.contactUsComp{
    /* margin-bottom: 8rem;
    margin-top: 5rem; */
    flex:6
}

.contactUsButton{
    background-color: #333333;
    color: whitesmoke;
}

@media screen and (max-width: 768px) {
    .contactUsContainer {
        flex-direction: column;
        gap:1rem;
      }

    .contactusTitle h1{
        font-size: 70px;
    }
}


@media screen and (max-width: 425px) {
    .contactUsContainer {
        margin-right: 3rem;
        margin-left: 3rem;
        margin-top: 2rem;
    }

    .contactusTitle h1{
        margin-top: 2rem;
    }

}