.ServiceInfo{
    background-color: #333333;
}

.scheduleTitle h1{
    font-size: 96px;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    color: #F1EEE9;
}

.scheduleContents h5{
    text-align: center;
    font-size: 40px;
    margin-top: 2rem;
    color: #022236;
}

.scheduleContents{
    /* display: flex; */
    /* flex-direction: column; */
    /* margin-right: 11rem;
    margin-left: 11rem; */
    background-color: #F1EEE9;
    border-radius: 1rem;
}

.scheduleItem{
    /* background-color: lightblue; */
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #888;
    
    /* margin-right: 2rem;
    margin-left: 2rem; */

}

.scheduleItemsCont{
    /* background-color: green; */
    display: flex;
    padding: 1rem;
    /* margin-right: 3rem; */
    /* margin-left: 3rem; */
    margin-bottom: 3rem;
    margin-top: 1rem;
    flex-direction: column;
    gap: 1rem;
}

@media screen and (max-width: 768px) {

      .scheduleTitle h1 {
        font-weight: bold;
        font-size: 42px;
      }
}