.givePage{
min-height: 100vh;
}

.giveButton {
    /* background: teal; */
    background-color: #333333;
    color: #f1eee9;
    border: 0;
    padding: 0.75rem;
    border-radius: 1.5rem;
    cursor: pointer;
    /* margin-top: 2rem;
    margin-bottom: 2rem; */
    text-decoration: none;
    font-size: 1.5rem;
    
  }

  .giveButton:hover {
    /* color: red; Specify your desired text color */
    text-decoration: none; /* Optional: To remove underline on hover */
    color: #f5f2ed;
    background-color: #555555;
  }

  .give h1{
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    /* font-size: auto; */
    color: #333333;
    text-align: center;
  
  
}

    


  .verseContainer{
   background-color:#afc4c0 ;
    border-radius: 1.5rem;
  }

  .verseFont{
    /* font-size: 100px; */
    font-weight: semibold;
  }


  @media screen and (max-width: 768px) {
    .contactUsContainer {
        flex-direction: column;
        gap:1rem;
      }

    .give h1{
        font-size: 70px;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
  }