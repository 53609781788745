.whoWeAreComp {
    position: relative;
    width: 100%;
    /* max-width: 100vw; */
    height: 100vh;
    background-image: url('../../assets/home-mountain\ copy.jpeg');
    background-size: cover;
    /* transform: scale(1.3); */
    /* translate: 0 -220px; */
    background-position: bottom;
    /* display: flex; */
    
  }
  
  .whoWeAreContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* transform: scale(0.7692); */
    translate: 0 -90px;
  }
  
  .whoWeAreText {
    color: #f1eee9;
  }
  
  .whoWeAreText h1 {
    font-weight: bold;
    font-size: 96px;
    color: #f1eee9;
  }


  /* iPhone SE */
  @media screen and (max-width: 320px) {
    .whoWeAreText h1 {
      font-weight: bold;
      font-size: 45px;
    }
  
    .whoWeAreText {
      text-shadow: 0px 2px 35px rgba(0, 0, 0, 0.697);
      /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.447); */
      border-radius: 0.5rem;
      /* background-color: #ffffff18; */
      /* backdrop-filter: blur(20px); */
    }
  }
  
  
  
  /* iPhone 8 */
  @media screen and (min-width: 321px) and (max-width: 375px) {
    .whoWeAreText h1 {
      font-weight: bold;
      font-size: 55px;
    }
    
    .whoWeAreText {
      text-shadow: 0px 2px 25px rgba(0, 0, 0, 0.394);
    }
  }
  
  /* iPhone 8 Plus */
  @media screen and (min-width: 376px) and (max-width: 768px) {
    .whoWeAreText h1 {
      font-weight: bold;
      font-size: 55px;
    }
    
    .whoWeAreText {
      text-shadow: 0px 2px 30px rgba(0, 0, 0, 0.354);
    }
  }