.navbackground{
    height: 80px;
    background-color: #f1eee9;
    /* translate: 0 80px; */

}
.nav{
    /* background-color: yellow; */
    background-image: linear-gradient(#f1eee9, #f1eee96e);
    backdrop-filter: blur(10px);
    /* background-color: #f1eee9; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0.5rem; */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    position: sticky;
    top: 0;
    z-index: 2;

    /* make flex direction col when mobile; */
}

.navcollapse{
    background-color: rgba(255, 255, 255, 0) !important;
}

.header1 {
    height: 5rem;
    /* background-color: aqua; */
    /* border-radius: 1rem 1rem 0px 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.triumphLogo{
    width: auto;
    height: 70px;
}

.triumphLogo3{
    width: auto;
    height: 40px;
}


@media screen and (max-width: 768px) {
    .nav {
        /* display: flex !important;
        flex-direction: column;
        align-items: center !important */
      }
  }

