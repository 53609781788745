.events{
    background-color: #F1EEE9;
}
.eventsTitle h1 {
    font-size: 96px;
    text-align: center;
    color: #333333;
   
}
.eventsContainer{
    /* display: flex; */
    justify-content: center;
    margin-top: 3rem;
    /* give each child flex 6 or justify content? */
    /* gap: 3rem; */
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 5rem;
}

.calendar{
    background-color: #AFC4C0;
    flex: 8;
    height: 90vh;
    padding: 1rem;
    border: 1px solid grey;
    border-radius: 1rem;
    /* max-width: 45rem; */
}

.eventContents{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    /* flex: 4; */

}

.eventItem{
    display: flex;
    background-color: #AFC4C0;
    border-radius: 20px;
    color: black;
    padding: 1.5rem;
    justify-content: space-between;
    gap: 3rem;
    max-width: 20rem;
    align-items: center;

}

@media screen and (max-width: 768px) {
    .eventsContainer {
        flex-direction: column;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
}

@media screen and (max-width: 768px) {
    .eventItem {
        max-width: unset;
    }

    .eventsTitle h1 {
        font-weight: bold;
        font-size: 58px;
        
    }
    
}

@media screen and (max-width: 820px) {
    .calendar {
        flex: 11;
        height: 60vh;
    }
}

