.footer{
    background-color: #333333;
    /* display: flex; */
    /* padding: 0.5rem; */
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    justify-content: space-between;
    font-weight: bold;
    color: #F1EEE9;
    /* background-color: #444; */
    /* border-radius: 1rem; */
    /* margin-bottom: 1rem; */
}

.triumphLogo2{
    width: auto;
    height: 80px;
    padding: 0.5rem;
}

.logoContainer2{
    /* background-color: lightblue; */
    /* display: flex;
    align-items: center; */
}

.footerDesc{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    justify-content: center;
   
}

.footerDesc p {
    font-size: 12px;
    color: whitesmoke;
}

.created{
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-bottom: 1rem;
    padding: 0;
  
    /* flex-direction: column;
    justify-content: flex-end;  */
}

.created p{
    font-size: 12px;
    /* line-height: 0; */
    color: whitesmoke;
    margin: 0;
}

